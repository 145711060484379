(function() {
    var $mobileMenuButton = $(".mobile-menu-button");
    var $mobileMenu = $(".mobile-menu");

    function toggleMobileMenu() {
        $mobileMenu.slideToggle();
    }

    function hideMobileMenuWhenClickedOutsideMobileMenuElements(event) {
        return targetContainsNoMobileMenuElement(event) ? hideMobileMenu() : true;
    }

    function targetContainsNoMobileMenuElement(event) {
        return targetContainsNo(event, '.mobile-menu') && targetContainsNo(event, '.mobile-menu-button');
    }

    function targetContainsNo(event, element) {
        return !$(event.target).closest(element).length;
    }

    function hideMobileMenu() {
        $mobileMenu.slideUp();
    }

    $(document).ready(function() {
        $mobileMenuButton.click(toggleMobileMenu);
        $(document).click(hideMobileMenuWhenClickedOutsideMobileMenuElements);
    });
})();