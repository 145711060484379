(function() {
    'use strict';

    var $newPatientForm = $('.new-patient-form');
    var $newPatientFormInputs = $newPatientForm.find('input');
    var $oldPatientRadio = $('#ancien_patient_oui');
    var $newPatientRadio = $('#ancien_patient_non');

    function toggleNewPatientForm() {
        if ($newPatientRadio.is(':checked')) {
            showNewPatientForm();
        } else {
            hideNewPatientForm();
        }
    }

    function showNewPatientForm() {
        $newPatientForm.slideDown();
        $newPatientForm.find('input').attr('required', true);
    }

    function hideNewPatientForm() {
        $newPatientForm.find('input').removeAttr('required');
        $newPatientForm.slideUp();
    }

    $(document).ready(function() {
        if ($oldPatientRadio.is(':checked')) {
            $newPatientForm.find('input').removeAttr('required');
            $newPatientForm.hide();
        }

        $newPatientRadio.change(toggleNewPatientForm);
        $oldPatientRadio.change(toggleNewPatientForm);
    });
})();