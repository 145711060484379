(function() {
    'use strict';

    var $showcase = $('.photo-showcase');
    var $slider = $('.photo-slider');
    var $playButton = $('.photo-slider-play');
    var $pauseButton = $('.photo-slider-pause');

    $(document).ready(function() {

        $showcase.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            fade: true,
            asNavFor: '.photo-slider'
        });

        $slider.slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.photo-showcase',
            centerMode: true,
            variableWidth: true,
            focusOnSelect: true,
            autoplay: false,
        });

        $playButton.click(function () {
            $slider.slick('slickPlay');
        });

        $pauseButton.click(function () {
            $slider.slick('slickPause');
        });

    });

})();

